'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const createUniqueIdGenerator = require('./createUniqueIdGenerator')
const changeDetectorProxy = require('./changeDetectorProxy')

const fixerVersionsNamespace = 'fixerVersions'
const fixerCategory = 'viewer_fixer'

const experimentStatusNew = 'new'
const experimentStatusOld = 'old'
const experimentStatusSkipped = 'skipped'

const logFixers = typeof localStorage !== 'undefined' && localStorage.getItem('dm-log-fixers') === 'true'

const hasFixerRunOnCurrentVersion = (pageJson, category, fixerName, fixerVersion) => {
    const {structure, data} = pageJson
    const {fixerVersionsQuery} = structure
    const lastVersionRun = _.get(data, [fixerVersionsNamespace, fixerVersionsQuery, category, fixerName])
    return lastVersionRun === fixerVersion
}

const createChangesAccumulator = (pageJson, fixerName, pageId) => {
    const modifiedPaths = []
    const changeCallback = (path, oldValue, newValue) => {
        if (!_.isEqual(oldValue, newValue)) {
            modifiedPaths.push(path.join('/'))
            if (logFixers) {
                console.log(`%c"${fixerName}" CHANGED page "${pageId}"`, 'color: yellow')
                console.log(`\t'${path.join('/')}'`, {oldValue, newValue})
            }
        }
    }
    return {
        pageJsonProxy: changeDetectorProxy.create(pageJson, changeCallback),
        hasModifications: () => modifiedPaths.length > 0,
        getModifiedPaths: () => modifiedPaths
    }
}

const createExperimentInstance = (captureError, experiments, fixerName, baseVersion, experimentalVersions) => {
    if (experimentalVersions === undefined) {
        experimentalVersions = []
    }

    const runningExperiments = {}
    let versionMissed = false
    let linearVersioningIsBroken = false

    _(experimentalVersions)
        .sortBy(['version'])
        .forEach(experimentalVersion => {
            if (!versionMissed && _.includes(experiments, experimentalVersion.experiment)) {
                runningExperiments[experimentalVersion.experiment] = experimentStatusNew
                baseVersion = experimentalVersion.version
            } else if (versionMissed && experiments[experimentalVersion.experiment]) {
                runningExperiments[experimentalVersion.experiment] = experimentStatusSkipped
                linearVersioningIsBroken = true
            } else {
                runningExperiments[experimentalVersion.experiment] = experimentStatusOld
                versionMissed = true
            }
        })

    const logContext = `Fixer:${fixerName}  RunningExperiments: ${JSON.stringify(runningExperiments)}`
    if (linearVersioningIsBroken) {
        captureError({
            message: `The experiments are opened non-linearly. ${logContext}`,
            errorType: 'NonLinearVersionError'
        })
    }
    const isOpen = name => {
        if (!runningExperiments[name]) {
            captureError({
                message: `Experiment used but not registered. ${logContext}`,
                errorType: 'NonRegisteredExperimentError'
            })
        }
        return runningExperiments[name] === 'new'
    }

    return {
        getRunningExperiments: () => runningExperiments,
        getValue: name => (isOpen(name) ? 'new' : ''),
        isMultiValueExperimentOpen: () => false,
        isOpen,
        version: baseVersion
    }
}

function fixPageData(
    plugins,
    {
        pageJson,
        urlFormatModel,
        isViewerMode,
        clientSpecMap,
        quickActionsMenuEnabled,
        experiments,
        pageIdsArray,
        pageId,
        fixerVersions = {},
        fixerChangesOnReruns,
        captureError = _.noop
    }
) {
    const magicObject = {}
    magicObject.isSlash = _.matchesProperty('format', coreUtils.siteConstants.URL_FORMATS.SLASH)(urlFormatModel)
    magicObject.pageIdToResolvedUriSEO = _.get(urlFormatModel, 'pageIdToResolvedUriSEO', {})
    magicObject.clientSpecMap = clientSpecMap
    magicObject.quickActionsMenuEnabled = quickActionsMenuEnabled
    magicObject.isExperimentOpen = spec => _.includes(experiments, spec)
    magicObject.isViewerMode = isViewerMode
    magicObject.dataFixerUtils = {
        uniqueIdGenerator: createUniqueIdGenerator(pageJson, pageId)
    }

    const fixerVersioningExperimentEnabled = _.includes(experiments, 'dm_fixerVersioning')

    const data = pageJson.data || {}
    data.document_data = data.document_data || {}
    data.theme_data = data.theme_data || {}
    data.component_properties = data.component_properties || {}
    data.mobile_hints = data.mobile_hints || {}
    pageJson.data = data

    if (isViewerMode || !fixerVersioningExperimentEnabled) {
        _.forEach(plugins, plugin => {
            const {version: fixerVersion, experimentalVersions} = plugin
            const experimentInstance = createExperimentInstance(
                captureError,
                experiments,
                plugin.name,
                fixerVersion,
                experimentalVersions
            )
            plugin.exec(pageJson, pageIdsArray, {...magicObject, experiment: experimentInstance})
        })
        return pageJson
    }

    const fixerVersionsForPage = {}
    fixerVersions[pageId] = {
        [fixerCategory]: fixerVersionsForPage
    }

    _.forEach(plugins, plugin => {
        const {
            name: fixerName,
            version: baseVersion,
            disableVersioning,
            disableFixerAfterFirstRun,
            experimentalVersions
        } = plugin
        const experimentInstance = createExperimentInstance(
            captureError,
            experiments,
            plugin.name,
            baseVersion,
            experimentalVersions
        )
        const fixerVersion = experimentInstance.version

        if (fixerChangesOnReruns && hasFixerRunOnCurrentVersion(pageJson, fixerCategory, fixerName, fixerVersion)) {
            if (disableFixerAfterFirstRun) {
                return
            }
            const changesAccumulator = createChangesAccumulator(pageJson, fixerName, pageId)
            plugin.exec(changesAccumulator.pageJsonProxy, pageIdsArray, {
                ...magicObject,
                experiment: experimentInstance
            })
            if (changesAccumulator.hasModifications()) {
                if (fixerChangesOnReruns[fixerName]) {
                    fixerChangesOnReruns[fixerName][pageId] = changesAccumulator.getModifiedPaths()
                } else {
                    fixerChangesOnReruns[fixerName] = {
                        ver: fixerVersion,
                        [pageId]: changesAccumulator.getModifiedPaths()
                    }
                }
            }
        } else {
            if (!disableVersioning) {
                fixerVersionsForPage[fixerName] = fixerVersion
            }
            plugin.exec(pageJson, pageIdsArray, {...magicObject, experiment: experimentInstance})
        }
    })

    return pageJson
}

module.exports = pluginsManager => fixPageData.bind(null, pluginsManager.getPlugins())
