'use strict'

const _ = require('lodash')
const refCompDelimiter = '_r_'
const REF_ARRAY_TYPE = 'RefArray'
const VARIANT_RELATION_TYPE = 'VariantRelation'
const BREAKPOINT_RELATION_TYPE = 'BreakpointRelation'

/**
 * @param {string} query
 * @return {string}
 */
function stripHashIfExists(query) {
    return query && (_.startsWith(query, '#') ? query.slice(1) : query)
}

/**
 * @param {Object} dataMap
 * @param {string} query
 * @return {Object}
 */
function getDataByQuery(dataMap = {}, query) {
    return dataMap[stripHashIfExists(query)] || {}
}

/**
 * Returns layoutDataItems of component
 * @param {Object} layoutDataMap
 * @param {Object} component
 * @returns {Object[]}
 */
function extractLayoutDataItems(layoutDataMap, component) {
    const layoutRefArray = getDataByQuery(layoutDataMap, component.layoutQuery)
    const refArrayIds = refArray.extractValuesWithoutHash(layoutRefArray)
    return _.compact(
        _.reduce(
            refArrayIds,
            (layoutDataItems, dataItemId) => {
                const dataItem = layoutDataMap[dataItemId]
                if (breakpointRelation.isBreakpointRelation(dataItem)) {
                    layoutDataItems.push(layoutDataMap[breakpointRelation.extractRefWithoutHash(dataItem)])
                } else if (variantRelation.isVariantRelation(dataItem)) {
                    layoutDataItems.push(layoutDataMap[variantRelation.extractToWithoutHash(dataItem)])
                } else {
                    layoutDataItems.push(dataItem)
                }
                return layoutDataItems
            },
            []
        )
    )
}
const isLocalVariant = (variant, seperateRemoteVariants) => {
    if (!seperateRemoteVariants) {
        return true
    }
    const id = _.isString(variant) ? variant : variant.id
    return !id.includes(refCompDelimiter)
}

const variantRelation = {
    /**
     * Checks if its variant relation
     * @param {Object} obj
     * @returns {boolean}
     */
    isVariantRelation(obj) {
        return obj.type === VARIANT_RELATION_TYPE
    },

    /**
     * Returns variants property of provided object
     * @param {{variants: string[]}} obj
     * @returns {boolean|string[]}
     */
    extractVariantsWithoutHash: obj => (obj && obj.variants ? _.map(obj.variants, stripHashIfExists) : false),
    /**
     * Returns to property of provided object without # prefix
     * @param {Object} obj
     * @returns {string}
     */
    extractToWithoutHash: obj => obj && obj.to && stripHashIfExists(obj.to),
    /**
     * Returns breakpointRelation object
     * @param {Object} data
     * @returns {{type: string, id: string, variants: string[], to: string, from: string}}
     */
    create: data => ({
        type: VARIANT_RELATION_TYPE,
        id: data.id,
        variants: data.variants ? _.map(data.variants, variant => `#${stripHashIfExists(variant)}`) : [],
        to: `#${stripHashIfExists(data.scopedDataId)}`,
        from: data.from
    }),
    createWithRemoteVariants: data => {
        const [variants, remoteVariants] = _.partition(data.variants, v => isLocalVariant(v, true))
        const result = {
            type: VARIANT_RELATION_TYPE,
            id: data.id,
            variants: variants ? variants.map(v => (_.isString(v) ? `#${stripHashIfExists(v)}` : v)) : [],
            to: `#${stripHashIfExists(data.scopedDataId)}`,
            from: data.from
        }

        if (remoteVariants.length > 0) {
            result.remoteVariants = remoteVariants.map(v => (_.isString(v) ? `#${stripHashIfExists(v)}` : v))
        }

        return result
    }
}

const breakpointRelation = {
    /**
     * Checks if its breakpoint relation
     * @param {Object} data
     * @returns {boolean}
     */
    isBreakpointRelation: data => data && data.type === BREAKPOINT_RELATION_TYPE,

    /**
     * Returns breakpoint property of provided object without # prefix
     * @param {Object} data
     * @returns {string}
     */
    extractBreakpointWithoutHash: data => data && data.breakpoint && stripHashIfExists(data.breakpoint),

    /**
     * Returns ref property of provided object without # prefix
     * @param {Object} data
     * @returns {string}
     */
    extractRefWithoutHash: data => data && data.ref && stripHashIfExists(data.ref),

    /**
     * Returns breakpointRelation object
     * @param {Object} data
     * @returns {{type: string,id:string,breakpoint:string,ref:string}}
     */
    create: data => ({
        type: BREAKPOINT_RELATION_TYPE,
        id: data.id,
        breakpoint: `#${stripHashIfExists(data.breakpoint)}`,
        ref: `#${stripHashIfExists(data.ref)}`
    })
}

const transformations = {
    createTransformationWithRotate: (id, rotate) => ({
        id,
        rotate,
        type: 'TransformData'
    })
}

/**
 * this is copy-paste from ds-impl dataModel
 * see {@link https://github.com/wix-private/document-management/blob/master/document-services-implementation/src/dataModel/dataModel.js}
 * @type {{extractValuesWithoutHash: *, create: *, update: *, isRefArray: *, extractValues: *}}
 */
const refArray = {
    /**
     * Returns values property of provided object
     *
     * @param {Object} obj
     * @returns {Array}
     */
    extractValues: obj => _.get(obj, 'values', []),
    /**
     * Returns values key of provided object stripping hashes(#)
     * @param {Object} obj
     * @returns {string[]}
     */
    extractValuesWithoutHash: obj => _.map(refArray.extractValues(obj), stripHashIfExists),
    /**
     * Returns reference array object
     * @param {Array} values
     * @returns {{values: Array, type: string}}
     */
    create: (values = []) => ({
        type: REF_ARRAY_TYPE,
        values: _.map(values, v => `#${stripHashIfExists(v)}`)
    }),
    /**
     * returns new object, based on obj with provided values property
     *
     * @param {Object} obj
     * @param {Array} values
     * @returns {Object}
     */
    update: (obj, values) => ({
        ...obj,
        values: _.map(values, v => `#${stripHashIfExists(v)}`)
    }),
    /**
     * Check if object has reference type
     *
     * @param {Object} obj
     * @returns {boolean}
     */
    isRefArray: obj => obj && obj.type === REF_ARRAY_TYPE
}

module.exports = {
    transformations,
    refArray,
    variantRelation,
    breakpointRelation,
    stripHashIfExists,
    getDataByQuery,
    extractLayoutDataItems
}
